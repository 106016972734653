import axios from "axios";

const IDENTITY = process.env.REACT_APP_IDENTITY_API_URL;

export const fetchMFAStatus = ({ userType, userName }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${IDENTITY}/login/init`, {
        idType: userType,
        idValue: userName,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        let errMsg = error;
        if (error.response && error.response.data.errorMessage) {
          errMsg = error.response.data.errorMessage;
        }
        reject(errMsg);
      });

    // setTimeout(() => {
    //   resolve({ isEnabled: true });
    // }, 1000);
  });

export const authenticate = ({ username, password, otp }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${IDENTITY}/login`, {
        userName: username,
        password: password,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        let errMsg = error;
        if (error.response && error.response.data.errorMessage) {
          errMsg = error.response.data.errorMessage;
        }
        reject(errMsg);
      });
  });

export const challengeMFA = ({ authenticatorType, mfaToken, retry = false }) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${IDENTITY}/login/mfa/challenge`, {
        authenticatorType: authenticatorType || undefined,
        mfaToken,
        retry,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        let errMsg = error;
        if (error.response && error.response.data.errorMessage) {
          errMsg = error.response.data.errorMessage;
        }
        console.log(errMsg);
        reject(errMsg);
      });
  });

  export const verifyMFAOtp = ({ authenticatorType, mfaToken, secret}) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${IDENTITY}/login/mfa/verify`, {
        authenticatorType: authenticatorType || undefined,
        mfaToken,
        secret,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve(response.data);
        }
      })
      .catch((error) => {
        let errMsg = error;
        if (error.response && error.response.data.errorMessage) {
          errMsg = error.response.data.errorMessage;
        }
        console.log(errMsg);
        reject(errMsg);
      });
  });
